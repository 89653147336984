<template>
	<div class="auth-wrapper auth-v1">
		<b-img src="../assets/images/bg-login.jpg" class="position-fixed"></b-img>
		<div class="auth-inner">
			<!-- Login v1 -->
			<b-card class="rounded-lg mx-3">
				<div class="container">
					<div class="row">
						<b-link class="brand-logo col-12 col-md">
							<b-img src="../assets/images/logo.png" alt="logo" />
						</b-link>
						<div class="col-12 col-md">
							<h1 class="font-weight-bold mb-1 text-center text-md-left">Login</h1>
							<!-- form -->
							<b-form class="auth-login-form mt-2 d-flex flex-column" @submit.prevent>
								<!-- email -->
								<b-form-group label-for="email" label="Email" label-class="font-weight-bold" class="mb-2">
									<b-form-input
										id="email"
										v-model="loginData.email"
										name="login-email"
										placeholder="Digite seu e-mail"
										autofocus
										class="bg-light"
									/>
								</b-form-group>

								<!-- password -->
								<b-form-group label-for="password" label="Senha" label-class="font-weight-bold">
									<b-input-group class="input-group-merge">
										<b-form-input
											id="password"
											v-model="loginData.password"
											:type="passwordFieldType"
											class="form-control-merge bg-light"
											name="login-password"
											placeholder="Digite sua senha"
										/>

										<!-- <b-input-group-append is-text>
											<feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
										</b-input-group-append> -->
									</b-input-group>
								</b-form-group>

								<!-- submit button -->
								<b-button
									class="align-self-end mt-1 col col-md-6"
									variant="primary"
									type="submit"
									:disabled="loading"
									@click="signIn"
								>
									<b-spinner v-if="loading" small />
									Entrar
								</b-button>
							</b-form>
						</div>
					</div>
				</div>
			</b-card>
			<!-- /Login v1 -->
		</div>
	</div>
</template>

<script>
	import { BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BImg, BInputGroup, BSpinner } from "bootstrap-vue";
	import { togglePasswordVisibility } from "@core/mixins/ui/forms";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			// BSV
			BButton,
			BForm,
			BFormInput,
			BFormGroup,
			BCard,
			BLink,
			BImg,
			BInputGroup,
			BSpinner,
		},
		mixins: [togglePasswordVisibility],
		data() {
			return {
				loading: false,
				loginData: {
					email: "",
					password: "",
					shouldPersistSession: false,
				},
			};
		},
		computed: {
			passwordToggleIcon() {
				return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
			},
		},
		methods: {
			async signIn() {
				this.loading = true;
				try {
					await this.$store.dispatch("auth/login", this.loginData);
					this.$router.push("/");
				} catch (error) {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Erro",
							text: error.message,
							variant: "danger",
							icon: "AlertCircleIcon",
						},
					});
				}
				this.loading = false;
			},
		},
	};
</script>

<style lang="scss">
	@import "@core/scss/vue/pages/page-auth.scss";
</style>
